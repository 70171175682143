import "@hotwired/turbo-rails"
import "alpine-turbo-drive-adapter"
import Alpine from "alpinejs"
import InstantClick from "instantclick"
 
window.Alpine = Alpine

document.addEventListener("alpine:initialized", () => {
  document.body.classList.add("alpine-initialized")
})

Alpine.start()


InstantClick.init(50)